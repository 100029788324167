import React from 'react';

import '../css/product.css';
import CustomWebCam from './webCam';

const Product = ({ url, 
    handleUpload, 
    id, 
    buttonLoad, 
    handleFrontView, 
    handleBackView, 
    handleLeftView, 
    handleRightView, 
    views, 
    imgSrc,
    handleReport,
    handleConfirm
 }) => {

    const style = {
        backgroundColor: 'green',
        color: 'white',
        fontWeight: '700',
        textAlign: 'center',
    };
    // const fv = views.fv ? style: null;

    // const retake = sessionStorage.getItem('done');
    // const isRetake = Boolean(retake);

    if (url === `/upload/${id}`) {
        return (
            <div className='product'>
            <div className='img-section'>
                <p className='view-txt'>Take images of the product ordered</p>
                <div style={{width: '100%'}}>
                    <CustomWebCam />
                </div>
            </div>
            <div className='view'>
                <div>
                    <p className='view-pos' id='top'>Top View</p>
                </div>
                <p className='view-pos' id='back'>Right View</p>
                <p className='view-pos' id='left'>Left View</p>
                <p className='view-pos' id='right'>Back View</p>
            </div>
            <div style={{marginTop: '70px'}}>
                <button className='in bt' onClick={handleUpload}>{buttonLoad ? 'Uploading...' : 'Upload'}</button>
            </div>
        </div>
        );
    }
    return (
        <div className='product'>
            <div className='img-section'>
                <p className='view-txt'>View live images of product ordered</p>
                <div>
                    <img src={imgSrc} className='img-pr' alt=''/>
                </div>
            </div>
            <div className='view' style={{ marginTop: '0'}}>
                <p className='view-pos' id='fv' style={ views.f ? style: null } onClick={handleFrontView}>Top View</p>
                <p className='view-pos' id='bv' style={ views.b ? style: null } onClick={handleBackView}>Back View</p>
                <p className='view-pos' id='lv' style={ views.l ? style: null } onClick={handleLeftView}>Left View</p>
                <p className='view-pos' id='rv' style={ views.r ? style: null } onClick={handleRightView}>Right View</p>
            </div>
            <div style={{marginTop: '30px'}}>
                <button className="in bt" onClick={handleConfirm}>Confirm</button>
            </div>
            <div style={{marginTop: '1px'}}>
                <button className="in bt" style={{ backgroundColor: 'red'}} onClick={handleReport}>Report Issue</button>
            </div>
        </div>
    );
};

export default Product;
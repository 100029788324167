import React from 'react';

import '../css/form.css';
// import '../css/login.css';
import orderImage from '../Images/orderImage.svg';

const formConfirm = ({ reName, rePh, reAdd, buttonLoad, handleReName, handleRePh, handleReAdd, handleSubmit }) => {
    const style = {
        backgroundColor: '#DCDCDC',
        fontWeight: 'lighter',
        textAlign: 'center',
        paddingLeft: '0'
    };

    const discountPrice = 0.9 * 4000

    return (
        <div className='main'>
            <div className='orderSection'>
                <h3 className='orderText'>What are you ordering?</h3>
                <img className='orderImage' src={orderImage} alt='' />
            </div>
            <div className='card'>
                <div className='container'>
                    <input className='or-in' value={reName} onChange={handleReName} required/>
                    <label>Receiver's Name</label>
                </div>
                <div className='container'>
                    <input className='or-in' value={rePh} onChange={handleRePh} required/>
                    <label>Receiver's Phone Number</label>
                </div>
                <div className='container'>
                    <input className='or-in' value={reAdd} onChange={handleReAdd} required/>
                    <label>Receiver's Address</label>
                </div>
                <div className='container'>
                    <input className='or-in' disabled value={'NEW CUSTOMER 90% OFF'} style={style}/>
                    <label>COUPON</label>
                </div>
                <div style={{ marginTop: '-20px', textAlign: 'center', marginBottom: '20px', fontWeight: '100' }}>
                    <em>Actual price ₦4000. 90% Discount Applied</em>
                </div>
                <div>
                    <button className="in bt" style={{ fontWeight: '900'}} onClick={handleSubmit}>{buttonLoad ? 'Please Wait...' : `PAY ₦${4000 - discountPrice}`}</button>
                </div>
            </div>
        </div>
    );
};
export default formConfirm;
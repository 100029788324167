import React from "react";

import '../css/login.css';

export const ForgotPassword = ({ email, loading, handleEmail, handleButton }) => {
    return (
        <div className="lg-body">
            <div className="lg-main">
                <div className="hd">
                    <h1 className="hd-h">Forgot Password</h1>
                    <p className="hd-p">Enter the email address you used to register with SendMe. You will receive an email to reset your password.</p>
                </div>
                <div className="card-form" style={{ paddingBottom: '70%' }}>
                    <div>
                        <form>
                            <input placeholder="Email" className="in in1" value={email} onChange={handleEmail} />
                            {/* <input placeholder="Password" className="in" /> */}
                        </form>
                    </div>
                    <div>
                        <button className="in bt" onClick={handleButton}>{loading ? 'Please Wait...' : 'Continue'}</button>
                    </div>
                    {/* <div>
                        <p className="fr-p">Forgot Password?</p>
                    </div> */}
                    {/* <div style={{ marginTop: '20px', fontWeight: '700' }}>
                        <p className="fr-p">Don't have an account? <a href='/register'>Create Account</a></p>
                    </div> */}
                </div>
            </div>
        </div>
    );
};



export const ResetPassword = ({ pass, confirmPass, error, loading, handlePass, handleConfirmPass, handleButton }) => {
    const errorStyle = {
        textAlign: 'center',
        marginTop: '0',
        color: 'red',
    };
    return (
        <div className="lg-body">
            <div className="lg-main">
                <div className="hd">
                    <h1 className="hd-h">Reset Password</h1>
                    <p className="hd-p">Enter your new password.</p>
                </div>
                <div className="card-form" style={{ paddingBottom: '50%'}}>
                    <div>
                        <form>
                            <input placeholder="New Password" className="in in1" value={pass} onChange={handlePass}/>
                            <input placeholder="Confirm New Password" className="in" value={confirmPass} onChange={handleConfirmPass}/>
                        </form>
                    </div>
                    {error ? <p style={errorStyle}>Passwords do not match</p>: null}
                    <div>
                        <button className="in bt" onClick={handleButton}>{loading ? 'Please Wait...': 'Reset Password'}</button>
                    </div>
                    {/* <div>
                        <p className="fr-p">Forgot Password?</p>
                    </div> */}
                    {/* <div style={{ marginTop: '20px', fontWeight: '700' }}>
                        <p className="fr-p">Don't have an account? <a href='/register'>Create Account</a></p>
                    </div> */}
                </div>
            </div>
        </div>
    );
};
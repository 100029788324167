import React from 'react';

import '../css/404.css';

const NotFound = () => {
    return (
        <div style={{ backgroundColor: 'white', display: 'block' }}>
          <h1 style={{ textAlign: 'center', color: 'black'}}>404 - Page Not Found</h1>
          <p style={{ textAlign: 'center', color: 'black'}}>Sorry, the page you are looking for does not exist.</p>
        </div>
      );
};

export default NotFound;

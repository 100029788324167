import React from "react";

import '../css/login.css';

export const Login = ({ user, pass, data, handleUser, handlePass, handleLogin, buttonText }) => {
    return (
        <div className="lg-body">
            <div className="lg-main">
                <div className="hd">
                    <h1 className="hd-h">Sign In</h1>
                    <p className="hd-p">Enter your email address and password to access your account or create an account</p>
                </div>
                <div className="card-form">
                    <div>
                        <form>
                            <input placeholder="Email" className="in in1" value={user || ''} onChange={handleUser}/>
                            <input placeholder="Password" className="in" value={pass} onChange={handlePass}/>
                        </form>
                    </div>
                    <div>
                        <button className="in bt" onClick={handleLogin}>{buttonText}</button>
                    </div>
                    <div>
                        <p className="fr-p"><a href='/forgot-password'>Forgot Password?</a></p>
                    </div>
                    <div style={{ marginTop: '20px', fontWeight: '700' }}>
                        <p className="fr-p">Don't have an account? <a href='/register'>Create Account</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
};
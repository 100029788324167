import React, { useRef, useState } from "react";
import Webcam from 'react-webcam';

const CustomWebCam = () => {
    const webcamRef = useRef(null);
    const [imgUrl, setImgUrl] = useState(null);
    const [currentViewIndex, setCurrentViewIndex] = useState(0);
    const view = ['Front', 'Back', 'Left', 'Right'];
    const [isRetake, setIsRetake] = useState(false);

    let camWidth = 380;

    function checkIphoneSE() {
        if (window.matchMedia('(max-width: 375px) and (max-height: 667px)').matches) {
            camWidth = 327
        } else {
          camWidth = 380
        }
      }
      
      // Call the function to check screen size
      checkIphoneSE();

    const dataURLtoFile = (dataurl, filename) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const storeImg = async (url) => {
        if (url === undefined) {
            return console.log('No URL Present');
        }

        if (currentViewIndex >= view.length) {
            alert('Please Upload Picture');
            return console.log('All Views have been stored');
        }

        const file = dataURLtoFile(url, `${view[currentViewIndex]}_image.png`);
        sessionStorage.setItem(view[currentViewIndex], URL.createObjectURL(file));

        return setCurrentViewIndex(prev => prev + 1);
    };

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        storeImg(imageSrc);
        setImgUrl(imageSrc);

        if (currentViewIndex === 0) {
            const style = document.getElementById('top').style;
            style.backgroundColor = 'green';
            style.color = 'white';
            style.fontWeight = '700';
            style.textAlign = 'center';
        }

        if (currentViewIndex === 1) {
            const style = document.getElementById('back').style;
            style.backgroundColor = 'green';
            style.color = 'white';
            style.fontWeight = '700';
            style.textAlign = 'center';
        }

        if (currentViewIndex === 2) {
            const style = document.getElementById('left').style;
            style.backgroundColor = 'green';
            style.color = 'white';
            style.fontWeight = '700';
            style.textAlign = 'center';
        }

        if (currentViewIndex === 3) {
            const style = document.getElementById('right').style;
            style.backgroundColor = 'green';
            style.color = 'white';
            style.fontWeight = '700';
            style.textAlign = 'center';

            setIsRetake(true);
            sessionStorage.setItem('done', true);
        }
    };

    const videoConstraints = {
        facingMode: "environment"
    };

    const retake = () => {
        if (currentViewIndex >= view.length) {
            setIsRetake(true);
            sessionStorage.setItem('done', true);
            alert('Please Upload Picture');
            return console.log('All Views have been stored');
        }
        setImgUrl(null);
    };

    if (imgUrl !== null) {
        return (
            <div>
                <img src={imgUrl} alt="" style={{ width: '100%' }} />
                <button onClick={retake} className={isRetake ? 'in' : 'in bt'} disabled={isRetake ? true : false}>Retake</button>
            </div>
        );
    } else {
        return (
            <div>
                <Webcam
                    height={282}
                    width={camWidth}
                    ref={webcamRef}
                    videoConstraints={videoConstraints}
                    screenshotFormat="image/png"
                />
                <button className='in bt' onClick={capture}>Capture {`${view[currentViewIndex]} View`}</button>
            </div>
        );
    }
};

export default CustomWebCam;

import React, { useState } from "react";

import { ForgotPassword } from "../StatelessComponent/forgot-password";

const ForgotPasswordCom = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value.toLowerCase().trim());
    };

    const handleButton = async () => {
        try {
            setLoading(true);

            const data = {
                email
            };

            const url = 'https://backend-vsqf.onrender.com/api/forgot-password';
            const request = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                },
            });
            const response = await request.json();

            if (request.status === 200) {
                setLoading(false);
                return alert(response.msg);
            };

            setLoading(false);
            return alert(response.msg);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };
    return (
        <ForgotPassword email={email}
            loading={loading}
            handleEmail={handleEmail}
            handleButton={handleButton}
        />
    );
};

export default ForgotPasswordCom;
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Product from "../StatelessComponent/product";

// Import Scripts
import fetchData from '../scripts/data';

// React Function to Handle the Submission of Image to the server

const Upload = () => {
    const [buttonLoad, setButtonLoad] = useState(false);


    const pathUrl = document.location.pathname;
    const id = useParams();
    if (pathUrl === `/upload/${id.id}`) {
        const body = document.getElementById('root');
        body.style.backgroundColor = '#ffffff'
    };


    const handleUpload = async () => {
        setButtonLoad(true);

        const front = sessionStorage.getItem('Front');
        const back = sessionStorage.getItem('Back');
        const left = sessionStorage.getItem('Left');
        const right = sessionStorage.getItem('Right');

        const urls = [front, back, left, right];
        const formData = new FormData();

        // Helper function to fetch Blob from URL
        const fetchBlobFromUrl = async (url) => {
            try {
                setButtonLoad(true);
                const response = await fetch(url);
        
                if (!response.ok) {
                    // Handle the error response
                    setButtonLoad(false);
                    alert('File Not Found');
                    throw new Error('File Not Found');
                }

                setButtonLoad(true);
                const blob = await response.blob();
                return blob;
            } catch (error) {
                // Handle the error
                setButtonLoad(false);
                alert('File Not Found. Refresh the Page and try capturing the image again.');
                // You can also return null or a default value if needed
                return null;
            }
        };
        
        for (let i = 0; i < urls.length; i++) {
            const url = urls[i];
            if (url) {
                const blob = await fetchBlobFromUrl(url);
                formData.append('image', blob, `image${i + 1}.png`);
            }
        }
    
        const url = 'https://backend-vsqf.onrender.com/api/upload'; // Adjust this to your server's endpoint
    
        const authToken = localStorage.getItem('token'); // Retrieve auth token from localStorage
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData, // Use formData as the body
                headers: {
                    'auth-token': authToken,
                    'bookId': id.id // Example bookId
                }
            });

            // const res = await response.json();
    
            if (!response.ok) {
                // console.log(res);
                setButtonLoad(false);
                alert('Failed to Upload images. Refresh the Page and try again');
                throw new Error('Failed to upload images');
            }
            setButtonLoad(false);
            const responseData = await response.json();
            console.log(responseData); // Log response from server
        } catch (error) {
            setButtonLoad(false);
            alert('Something Went Wrong!!');
            console.error('Error uploading images:', error);
        }
    };
    

    // Data();

    useEffect(() => {
        fetchData();
    }, []);
    return (
        <Product url={pathUrl}
            handleUpload={handleUpload}
            id={id.id}
            buttonLoad={buttonLoad}
         />
    );
};

export default Upload;
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ResetPassword } from '../StatelessComponent/forgot-password';

const ResetPasswordCom = () => {
    const { id } = useParams();

    const[pass, setPass] = useState('');
    const[confirmPass, setConfirmPass] = useState('');
    const[loading, setLoading] = useState(false);
    const[error, setError] = useState(false);

    const handlePass = (e) => {
        e.preventDefault();
        setPass(e.target.value).trim();
    };

    const handleConfirmPass = (e) => {
        e.preventDefault();
        setConfirmPass(e.target.value).trim();
    };

    const handleButton = async () => {
        try {
            setLoading(true);

            const data = {
                pass,
                confirmPass
            };

            const url = 'https://backend-vsqf.onrender.com/api/reset-password';
            const request = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'reset-auth-token': id,
                },
            });
            
            if (request.status === 200) {
                return document.location.href = '/login';
            };

            const response = await request.json();
            setLoading(false)
            return alert(response.msg);
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    };

    useEffect(() => {
        if (pass !== confirmPass) {
            return setError(true);
        }

        return setError(false);
    }, [pass, confirmPass])
    return (
        <ResetPassword 
            pass={pass}
            confirmPass={confirmPass}
            error={error}
            loading={loading}
            handlePass={handlePass}
            handleConfirmPass={handleConfirmPass}
            handleButton={handleButton}
        />
    );
};

export default ResetPasswordCom;
import React from "react";

import '../css/404.css';

const NotFoundCom = () => {

    // function isLargerDevice() {
    //     // Define a threshold for screen width that distinguishes between mobile and larger devices.
    //     // For this example, we assume that screens larger than 768px in width are not mobile devices.
    //     const threshold = 768;
      
    //     // Get the screen width
    //     const screenWidth = window.innerWidth;
      
    //     // Return true if the screen width is greater than the threshold, otherwise false
    //     return screenWidth > threshold;
    // }
    // const body = document.body.style.backgroundColor = '#E19400';
        // document.getElementById('#root').style.backgroundColor = '#E19400';
    return (
        <div style={{ backgroundColor: '#E19400', display: 'block' }}>
            <h1 style={{ textAlign: 'center', color: 'black'}}>Please visit us on your mobile device</h1>
            <p style={{ textAlign: 'center', color: 'black'}}>We are not available on the mobile version of our website.</p>
        </div>
    );
};

export default NotFoundCom;
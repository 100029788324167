import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import logo from './logo.svg';
// import './App.css';
import { MainLogin } from './StatefulComponent/Login';
import { RegisterMain } from "./StatefulComponent/Register";
import OrderForm from "./StatefulComponent/Order";
import ProductMain from "./StatefulComponent/Product";
import Upload from "./StatefulComponent/Upload";
import OrderConfirm from "./StatefulComponent/orderConfirm";
import PaystackPay from "./StatefulComponent/Payment";

import NotFound from "./StatelessComponent/404";
import NotFoundCom from "./StatefulComponent/404";
import ForgotPasswordCom from "./StatefulComponent/ForgotPasswordCom";
import ResetPasswordCom from "./StatefulComponent/ResetPasswordCom";

function App() {

  

  function isLargerDevice() {
    // Define a threshold for screen width that distinguishes between mobile and larger devices.
    // For this example, we assume that screens larger than 768px in width are not mobile devices.
    const threshold = 768;
  
    // Get the screen width
    const screenWidth = window.innerWidth;
  
    // Return true if the screen width is greater than the threshold, otherwise false
    return screenWidth > threshold;
  }
  
  // Example usage
  if (isLargerDevice()) {
    console.log('This is a laptop, iPad, or larger screen.');
    localStorage.setItem('lp', true);
    return (
      <Router>
        <Routes>
          <Route path="/" Component={NotFoundCom} />
        </Routes>
      </Router>
    );
  } else {
    console.log('This is a mobile phone or smaller screen.');
    return (
      <Router>
        <Routes>
          <Route path='/' Component={MainLogin} />
            <Route path='/login' Component={MainLogin} />
            <Route path='/register' Component={RegisterMain} />
            <Route path='/order' Component={OrderForm} />
            <Route path='/verify/:id' Component={ProductMain} />
            <Route path="/upload/:id" element={<Upload />} />
            <Route path='/order-confirm' Component={OrderConfirm} />
            <Route path='/payment' Component={PaystackPay} />
            <Route path='/forgot-password' Component={ForgotPasswordCom} />
            <Route path='/reset-password/:id' Component={ResetPasswordCom} />
            <Route path="*" element={<NotFound />} />
        </Routes> 
      </Router>
    );
  }
  
}

export default App;

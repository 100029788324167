const fetchData = async () => {
    try {
        const url = 'https://backend-vsqf.onrender.com/api/';
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'auth-token': localStorage.getItem('token'),
            }
        });

        const responseData = await response.json();

        if (response.status === 401) {
            localStorage.setItem('redirect_url', document.location.href);
            document.location.href = '/login';
        };

        if (response.status !== 200) {
            document.location.href = '/login';
            return;
        }

        const { username, email, phone } = responseData.msg;

        // setUser({ name, email, phone });
        // console.log({ username, email, phone });
        // console.log(responseData);
        return { username, email, phone };

    } catch (error) {
        // alert(`${error}. Please Check your internet connection and try again`);
        console.error(error);
    }
};

export default fetchData;

import React, { useState } from "react";
import { Login } from "../StatelessComponent/login";

export const MainLogin = () => {
    const[ user, setUser ] = useState('');
    const[ pass, setPass ] = useState('');
    const[ data, setData ] = useState({});
    const[ buttonText, setButtonText ] = useState('Sign In');

    const handleUser = (e) => {
        e.preventDefault();
        setUser(e.target.value.toLowerCase().trim());
    };

    const handlePass = (e) => {
        e.preventDefault();
        setPass(e.target.value.trim());
    };

    const handleLogin = async () => {
        const redirect_url = localStorage.getItem('redirect_url');
        // console.log(redirect_url || 'My name');
        try {

            setButtonText('Signing In...');
            const url = 'https://backend-vsqf.onrender.com/api/auth/login';

            const req = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({ email: user, pass }),
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const res = await req.json();
            console.log(req.status);
            // console.log(res);

            if (req.status !== 200) {

                 console.log(res);
                setButtonText('Sign In');
                return alert(res.msg);
            };

            setData(res);
            localStorage.setItem('token', res.token);
            setButtonText('Sign In');
            return document.location.href = redirect_url || '/order';
        } catch (error) {
            setButtonText('Sign In');
            alert('Check your Internet Connection');
        }
    };

    // useEffect(() => {
    //     handleLogin();
    // }, []);

    return (
        <Login user={user}
            pass={pass}
            data={data}
            handleUser={handleUser}
            handlePass={handlePass}
            handleLogin={handleLogin}
            buttonText={buttonText}
        />
    );
};
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from "react-router-dom";

import Product from '../StatelessComponent/product';
import demoImage from '../Images/demo.png';

import fetchData from '../scripts/data';

const ProductMain = () => {
    const { id } = useParams();

    useEffect(() => {
        if (window.location.pathname === `/verify/${id}`) {
            const body = document.getElementById('root');
            body.style.backgroundColor = '#ffffff';
        }
    }, [id]);

    const [views, setViews] = useState({
        f: true,
        b: false,
        l: false,
        r: false
    });
    const [getImg, setGetImg] = useState([]);
    const [imgSrc, setImgSrc] = useState(demoImage);

    const handleFrontView = () => {
        setViews({
            f: true,
            b: false,
            l: false,
            r: false,
        });

        if (getImg[0]) {
            setImgSrc(getImg[0].viewUrl);
        }
    };

    const handleBackView = () => {
        setViews({
            f: false,
            b: true,
            l: false,
            r: false,
        });

        if (getImg[1]) {
            setImgSrc(getImg[1].viewUrl);
        }
    };

    const handleLeftView = () => {
        setViews({
            f: false,
            b: false,
            l: true,
            r: false,
        });

        if (getImg[2]) {
            setImgSrc(getImg[2].viewUrl);
        }
    };

    const handleRightView = () => {
        setViews({
            f: false,
            b: false,
            l: false,
            r: true,
        });

        if (getImg[3]) {
            setImgSrc(getImg[3].viewUrl);
        }
    };

    const handleConfirm = () => {
        alert(`Thank you for confirming your order! We're glad everything is in order. If you need any assistance, please reach out to us.`)
    };

    const handleReport = () => {
        alert(`We're sorry to hear there's an issue with your order. Please provide details so we can coordinate with the seller and resolve it promptly.`)
    };

    const getImgFunc = useCallback(async () => {
        const url = 'https://backend-vsqf.onrender.com/api/image';

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'auth-token': localStorage.getItem('token'),
                    'media_id': id,
                }
            });

            const responseData = await response.json();

            if (response.status !== 200) {
                alert(responseData.msg);
                return;
            }

            setGetImg(responseData.msg);

        } catch (error) {
            alert(`${error}. Please Check your internet connection and try again`);
            console.error(error);
        }
    }, [id]);

    useEffect(() => {
        getImgFunc();
    }, [getImgFunc]);

    useEffect(() => {
        if (getImg.length > 0) {
            setImgSrc(getImg[0].viewUrl); // Set the first image from the fetched data
        }
    }, [getImg]);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <Product
            imgSrc={imgSrc}
            views={views}
            handleFrontView={handleFrontView}
            handleBackView={handleBackView}
            handleLeftView={handleLeftView}
            handleRightView={handleRightView}
            handleConfirm={handleConfirm}
            handleReport={handleReport}
        />
    );
};

export default ProductMain;

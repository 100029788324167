import React, { useEffect, useState } from "react";


import fetchData from "../scripts/data";


const PaystackPay = () => {
    const publicKey = 'pk_test_055d2b0fcf35f38aa6ab8b6a9a73e5cb268b279d';
    const [data, setData] = useState(null);

    const handleData = async () => {
        const userData = await fetchData();
        setData(userData);
    };

    useEffect(() => {
        handleData();
    }, []);

    useEffect(() => {
        if (data) {
            const onSuccess = () => {
                alert("Your visual verification request with SendMe is confirmed. We are processing your booking and will notify you once the product photo is ready. Our founders will personally handle the delivery. Thank you for choosing SendMe!");
            };
        
            const onClose = () => {
                // alert("Wait! You need this oil, don't go!!!!");
                document.location.href = '/order';
            };
        
            const payWithPaystack = () => {
                const handler = window.PaystackPop.setup({
                key: publicKey,
                email: data.email,
                amount: 40000,
                metadata: {
                    custom_fields: [
                    {
                        display_name: data.username,
                        variable_name: 'phone',
                        value: data.phone,
                    },
                    ],
                },
                callback: function(response) {
                    console.log(response); // You can handle the response here
                    onSuccess();
                },
                onClose: function() {
                    onClose();
                },
                });
        
                handler.openIframe();
            };
        
            payWithPaystack();
        }
    }, [data]);

    if (!data) {
        return (
            <div style={{ backgroundColor: 'white' }}>
                <h4>Please Wait...</h4>
            </div>
        );
    }

    return null;
};

export default PaystackPay;
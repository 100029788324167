import React, { useState, useEffect } from "react";

import FormConfirm from "../StatelessComponent/formConfirm";
import fetchData from '../scripts/data';

const OrderConfirm = () => {
    // Change the background color of order url
    const pathUrl = document.location.pathname;
    if (pathUrl === '/order-confirm') {
        const body = document.body;
        console.log('Order URL')
        body.style.backgroundColor = '#E19400'
    };

    const [user, setUser] = useState({});
    const [reName, setReName] = useState('');
    const [rePh, setRePh] = useState('');
    const [reAdd, setReAdd] = useState('');
    const [buttonLoad, setButtonLoad] = useState(false);

    const handleReName = (e) => {
        e.preventDefault();
        setReName(e.target.value);
    };

    const handleRePh = (e) => {
        e.preventDefault();
        setRePh(e.target.value);
    };

    const handleReAdd = (e) => {
        e.preventDefault();
        setReAdd(e.target.value);
    };

    const handleSubmit = async () => {
        try {
            setButtonLoad(true);

        const details = localStorage.getItem('details');
        const parseDetails = JSON.parse(details);
        console.log({...parseDetails, reName, reAdd, rePh});

        const data = {...parseDetails, reName, reAdd, rePh, amount: 400}

        const url = 'https://backend-vsqf.onrender.com/api/auth/booking';

        const req = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'auth-token': localStorage.getItem('token'),
            },
        });

        const res = await req.json();

        if (req.status !== 200) {
            setButtonLoad(false);
            return alert(res.msg);
        };
        setButtonLoad(false);
        return localStorage.removeItem('details');
        //  return document.location.href = '/payment'
        } catch (error) {
            alert('Failed to fetch')
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData(setUser);
    }, []);

    return (
        <div>
            <FormConfirm
                user={user}
                reName={reName}
                rePh={rePh}
                reAdd={reAdd}
                buttonLoad={buttonLoad}
                handleReName={handleReName}
                handleRePh={handleRePh}
                handleReAdd={handleReAdd}
                handleSubmit={handleSubmit}
            />
        </div>
    );
};

export default OrderConfirm;